import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import base from './base.css'
import Navigation from './navigation'
import Footer from './footer'
import { Helmet } from 'react-helmet'
class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header
    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }
    return (
      <React.Fragment>
      <Helmet title="V-Tech Solutions">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>
      </Helmet>  
      <div className="site-container">
        <Navigation />
        {children}
        <Footer/>
      </div>
      </React.Fragment>
    )
  }
}
export default Template
