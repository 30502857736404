import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import facebook from '../assets/images/facebook.png'
import twitter from '../assets/images/twitter.png'
import instagram from '../assets/images/instagram.png'
import backToTop from '../assets/images/back-to-top.png'




export default () => (
    <Row id="footer">
        <Col>
    <Container>
        <Row>
            <Col md="8">
                <ul id="footer-menu">
                    <li><a href="/about">ABOUT US</a></li>
                    <li><a href="/careers">CAREERS</a></li>
                    <li><a href="/media">MEDIA</a></li>
                    <li><a href="/contact">CONNECT</a></li>
                </ul>
                <p id="copyright">© 2020 V-Tech Solutions. All Rights Reserved</p>
            </Col>
            <Col md="4">
                <ul id="footer-social">
                <li><a target="_blank" href="https://twitter.com/VTechDC"><img src={twitter}/></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/vtechdc/"><img src={instagram}/></a></li>
                    <li><a target="_blank" href="https://www.facebook.com/vtechdc"><img src={facebook}/></a></li>
                </ul>                
                 <img onClick={() => {window.scrollTo({top: 0,left: 0,behavior: 'smooth'})}} id="backToTop" src={backToTop} />
            </Col>
            </Row>
    </Container>
    </Col>
    </Row>

)

