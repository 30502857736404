import React, { useState, useEffect, useMemo } from 'react'
import { StaticQuery, graphql } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import logo from '../assets/images/logo.png'
const Navigation = () => {
  const [width, setWidth] = useState(null)
  const isCollapsed = useMemo(() => {
     if(width >= 993) { return false; } 
     else { return true }
   },[width])
const setWindowSize = () => {
    setWidth(window.innerWidth)
}
useEffect(()=> {
    setWindowSize()
    window.addEventListener('resize', setWindowSize);
    window.addEventListener('load', setWindowSize);
},[width])
return (
  <StaticQuery
  query={graphql`
    query NavQuery {
      allContentfulJobListingLink {
        edges {
          node {
            link
          }
        }
      }
    }
  `}
  render={data => (
  <Navbar expand="lg" sticky="top">
    <Container>
    <Navbar.Brand href="/">
      <img
        src={logo}
        className="d-inline-block align-top"
        id="logo"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto nav-custom">
      <Nav.Link className="my-auto" href="/about">ABOUT US</Nav.Link>
      <Nav.Link className="my-auto" href="/services">SERVICES</Nav.Link>
      <Nav.Link className="my-auto" href="/careers">CAREERS</Nav.Link>
      <Nav.Link className="my-auto" href="/media">MEDIA</Nav.Link>
      <Nav.Link className="my-auto" href="/connect">CONNECT</Nav.Link>
      <Nav.Link className="header-cta" href={data.allContentfulJobListingLink.edges[0].node.link} target="_new">APPLY NOW</Nav.Link>
    </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  )}
  />
  )
}
export default Navigation
